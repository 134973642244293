.saveTheDateContainer {
  color: #4a4c4e;
  background-color: #fafafa;
}

.saveTheDateIcon {
  width: 60%;
  margin: 0 auto;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 35px;
  color: #707070;
  cursor: pointer;
}

.saveTheDateIcon a {
  text-decoration: none;
  color: #707070;
}

.saveTheDateIcon a:hover {
  color: #707070;
}

.saveTheDateIcon h4 {
  font-size: 14px;
  margin-top: 10px;
  font-family: 'Volvo Novum Light';
  font-weight: 300;
}

.atcb-text {
  overflow-wrap: anywhere;
  display: none !important;
}

.saveTheDateContainer .atcb-button {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  color: #707070;
  cursor: pointer;
  display: block;
  max-width: 1px;
  min-width: 30px;
  font-family: 'Volvo Novum Regular';
  font-size: 35px;
  font-weight: 100;
  justify-content: center;
  padding: 0 !important;
  margin: 0;
  position: relative;
  text-align: center;
  touch-action: none;
  z-index: 1;
}

.saveTheDateContainer
  .atcb-button.atcb-active:not(.atcb-modal-style, .atcb-dropoverlay),
.atcb-button.atcb-single:focus,
.atcb-button.atcb-single:hover {
  box-shadow: none;
}

.saveTheDateContainer .atcb-icon-outlookcom > svg > path {
  fill: #707070;
}

.saveTheDateContainer .atcb-button-wrapper {
  padding: 0px;
}

.saveTheDateContainer .atcb-saved .atcb-checkmark {
  display: none;
}
