.dialog-container {
  position: fixed;
  inset: 0;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease-out;
}

.backdrop-closed {
  opacity: 0;
}

.dialog-wrapper {
  position: fixed;
  inset: 0;
  z-index: 10;
  width: 100vw;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-sheet-wrapper {
  align-items: flex-end;
}

.dialog-content {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.bottom-sheet-content {
  align-items: flex-end;
}

.dialog-panel {
  width: 45vw;
  min-width: 400px;
  height: auto;
  max-height: 90vh;
  overflow-y: auto;
  background-color: white;
  backdrop-filter: blur(24px);
  transition: all 0.3s ease-out;
  border-radius: 1rem;
}

.bottom-sheet-panel {
  border-radius: 1rem 1rem 0 0;
}

.dialog-panel[data-state='closed'] {
  transform: scale(0.95);
  opacity: 0;
}

.bottom-sheet-panel[data-state='closed'] {
  transform: translateY(100%);
}

@media (min-width: 768px) {
  .dialog-panel {
    max-width: 80vw;
    max-height: 85vh;
  }

  .bottom-sheet-panel {
    border-radius: 1rem;
    max-height: 100%;
  }

  .bottom-sheet-panel[data-state='closed'] {
    transform: translateY(0) scale(0.95);
    opacity: 0;
  }
}
