/* @import '../../stylesheets/common'; */

.videoContainer {
  width: 80%;
  height: 100%;
  aspect-ratio: 16 / 9;
  margin: 44px auto 0;
  background-color: #f3f3f3;
  text-align: center;
  overflow: hidden;
  position: relative;
  font-family:
    Volvo Novum Medium,
    Arial,
    sans-serif;
}

.videoContainer img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.videoContainer pre {
  font-family:
    Volvo Novum Medium,
    Arial,
    sans-serif;
  font-size: larger;
  margin-bottom: 30px;
}

.videoContainer .title {
  margin-bottom: 30px;
}

.titlesContainer {
  margin: 40px auto;
  width: 45%;
  font-family:
    Volvo Novum Light,
    Arial,
    sans-serif;
}

.titlesContainer pre {
  font-family: 'Volvo Novum Light';
}

.discription {
  font-family: 'Volvo Novum Light';
  margin-top: 15px;
  margin-bottom: 15px;
}

.carouselContainer {
  font-family: Volvo Novum Medium;
  text-align: center;
  padding: 20px 50px;
  position: relative;
}

.carouselContainerBg {
  background-color: var(--background-secondary);
}

@media only screen and (max-width: 820px) {
  .videoContainer {
    margin: 0;
  }
  .titlesContainer {
    margin: 0 auto;
  }
}

.vl-description {
  color: var(--text-secondary);
  font-family:
    Volvo Novum Light,
    Arial,
    sans-serif;
}

.titlesInnerContainer {
  width: 50%;
  text-align: left;
}

.titlesContainer h2 {
  font-family:
    Volvo Novum Medium,
    Arial,
    sans-serif;
}

.vl-videoInfo {
  margin-top: 20px;
  margin-bottom: 30px;

  @media (--large-viewport) {
    margin-top: 40px;
    margin-bottom: 50px;
  }

  h2 {
    text-align: left;
    margin-bottom: 20px;

    @media (--large-viewport) {
      margin-bottom: 30px;
    }
  }

  .vl-broadcastDate {
    margin-bottom: 8px;
    color: var(--text-secondary);
    font-size: 12px;

    @media (--large-viewport) {
      font-size: 14px;
    }

    span {
      margin-right: 10px;
    }
  }

  .vl-views {
    font-size: 12px;
    margin-bottom: 8px;
    color: var(--text-secondary);
    @media (--large-viewport) {
      font-size: 14px;
    }
  }
}

.vl-always-center {
  grid-column: 1 / 13;

  @media (--large-viewport) {
    margin: 0px;
    grid-column: 4 / 10;
  }
}

.vl-title {
  margin-bottom: 24px;
}

@media only screen and (max-width: 992px) {
  .videoContainer {
    width: 100%;
  }

  .carouselContainer {
    font-family: Volvo Novum Medium;
    text-align: center;
    padding: 20px;
    position: relative;
  }

  .titlesContainer {
    padding: 20px;
    width: 100%;
  }

  .titlesContainer pre,
  .vl-broadcastDate {
    font-size: 12px;
  }

  .titlesInnerContainer {
    width: 80%;
  }
}

.vl-title1 {
  font-size: 40px;
  display: inline;
  margin-right: 3px;
  color: var(--text-primary);
  font-family:
    Volvo Novum Medium,
    Arial,
    sans-serif;
}

.vl-title2 {
  display: inline;
  color: #707070 !important;
  font-size: 40px;
  margin-bottom: 30px;
}

.vl-channel {
  font-size: 14px;
  color: var(--text-secondary);
  text-decoration: none;
  font-family:
    Volvo Novum Light,
    Arial,
    sans-serif;
}

h1 {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;

  @media (--large-viewport) {
    font-size: 32px;
    font-weight: 500;
    line-height: 62px;
    letter-spacing: 0em;
    text-align: center;
  }
}

h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;

  @media (--large-viewport) {
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
  }
}

h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  @media (--large-viewport) {
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
  }
}

h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  @media (--large-viewport) {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
}

h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.countdown {
  margin: 0 auto;
  color: #000;
  justify-content: center;
  text-align: center;
  font-family:
    Volvo Novum Medium,
    Arial,
    sans-serif;
}

.views {
  margin-bottom: 10px;
}

#endedSec {
  grid-column: 1 / 4;
  margin-top: 40px;
}

.vl-Alert--info {
  @extend %alert;
  background-color: rgba(0, 0, 0, 0.3);

  margin: 20px;
  padding: 20px;

  a {
    color: white;
    font-size: inherit;

    &:hover {
      color: white;
      text-decoration: underline;
    }
  }

  h1 {
    color: #ffffff;
  }

  .preheader {
    color: #ffffff;
  }

  .vl-countdown-child {
    svg {
      stroke: #ffffff;
    }

    .countdown-child__inner {
      span {
        color: #ffffff;
      }
    }
  }
}

.vl-u-center-inline {
  text-align: center;
}

@media (min-width: 1024px) {
  .navContainer {
    width: 80%;
    margin: 0 auto;
  }
}
