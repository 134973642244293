.featureWebcast {
  margin: 40px auto;
  width: 50%;
}

.video-btn-container {
  display: flex;
  justify-content: center;
  margin-top: 36px;
  margin-bottom: 92px;
}
.video-btn {
  width: 232px;
}

.channel-carousel-container {
  font-family: Volvo Novum Medium;
  text-align: center;
  padding: 20px 50px;
  position: relative;
}

.live-webcast-container {
  margin: 32px auto;
  width: 50%;
}

@media only screen and (max-width: 992px) {
  .videoContainer {
    width: 100%;
  }

  .channel-carousel-container {
    font-family: Volvo Novum Medium;
    text-align: center;
    padding: 20px;
    position: relative;
  }
  .live-webcast-container {
    width: 100%;
    padding: 20px;
  }
}
