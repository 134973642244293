.featured-webcast {
  background-color: var(--background-secondary);
}

.featured-webcast-container {
  width: 60%;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 20px;
  overflow: hidden;
  text-align: center;
}

.featured-webcast-header {
  margin-bottom: 30px;
}

.featured-webcast-title {
  font-family: 'Volvo Novum Medium', sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  color: var(--text-primary);
  margin: 0;
}

.featured-webcast-image-wrapper {
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.featured-webcast-image {
  width: 80vw;
  max-width: 816px;
  height: auto;
  aspect-ratio: 816/460;
  object-fit: cover;
}

.featured-webcast-content {
  margin-top: 24px;
  text-align: left;
  padding: 0 60px;
  margin-bottom: 36px;
}

.featured-webcast-link {
  text-decoration: none;
}

.featured-webcast-date {
  color: var(--text-secondary);
  font-size: 12px;
  font-family: 'Volvo Novum Light', sans-serif;
  margin-bottom: 16px;
}

.featured-webcast-heading-container {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 32px;
  font-family:
    Volvo Novum Medium,
    Arial,
    sans-serif;
  font-size: 32px;
  color: var(--text-primary);
  margin-bottom: 20px;
}

.featured-webcast-heading1 {
  color: var(--text-primary);
}

.featured-webcast-heading2 {
  color: #707070;
  margin-bottom: 30px;
}

.featured-webcast-description {
  color: var(--text-secondary);
  font-family:
    Volvo Novum Light,
    Arial,
    sans-serif;
}

.featured-webcast-btn {
  width: 198px;
  height: 48px;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .featured-webcast-container {
    width: 100%;
  }

  .featured-webcast-image {
    max-width: 366px;
    aspect-ratio: 366 / 203;
    width: 85vw;
  }

  .featured-webcast-title {
    font-size: 24px;
    line-height: 29px;
    text-align: left;
    margin-bottom: 20px;
    padding-left: 24px;
  }

  .featured-webcast-content {
    margin-left: 12px;
    padding: 20px 20px;
  }

  .featured-webcast-heading1 {
    font-size: 24px;
  }

  .featured-webcast-heading2 {
    font-size: 24px;
  }
  .featured-webcast-date,
  .featured-webcast-heading-container {
    margin-bottom: 10px;
  }
  .featured-webcast-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
