[data-theme='light'] {
  --background-primary: var(--color-white);
  --background-secondary: var(--color-grey-lightest);
  --text-primary: var(--color-off-black);
  --text-secondary: var(--color-grey-darkest);
  --accent-primary: var(--color-blue-darker);
  --accent-secondary: var(--color-blue-light);
  --border-color: var(--color-grey-darker);
  --button-text: var(--color-white);
  --header-icon: var(--color-header-icon-light);
  --footer-bg: var(--color-grey-lightest);
  --skelaton-bg: var(--color-skelaton-bg-light);
  --btn-color: var(--color-blue-darker);
}

[data-theme='dark'] {
  --background-primary: var(--color-off-black);
  --background-secondary: var(--color-black);
  --text-primary: var(--color-white);
  --text-secondary: var(--color-grey-light);
  --accent-primary: var(--color-blue-light);
  --accent-secondary: var(--color-blue-lightest);
  --border-color: var(--color-grey-darker);
  --button-text: var(--color-black);
  --header-icon: var(--color-header-icon-dark);
  --footer-bg: var(--color-footer-bg-dark);
  --skelaton-bg: var(--color-footer-bg-dark);
  --btn-color: var(--color-grey-light);
}
