.search-box-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.input-container {
  width: 100%;
}

.input-field {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--btn-color);
  border-radius: 3px 0 0 3px;
  outline: none;
  background: var(--background-primary);
  color: var(--text-primary);
}

.error-message {
  color: red;
  font-size: 12px;
}

.search-button {
  background: var(--background-secondary);
  border: 1px solid var(--btn-color);
  border-left: none;
  border-radius: 0 3px 3px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.search-button .search-icon {
  color: var(--text-primary);
}
