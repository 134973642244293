.banner {
  width: 100%;
  height: 514px;
  position: relative;
  display: flex;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  font-family: 'Volvo Novum Medium', Arial, sans-serif;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: 1;
}

.banner-content {
  z-index: 2;
  position: relative;
  margin-top: 5rem;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.banner-date {
  font-family: 'Volvo Novum Medium', Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 1.25rem;
}

.banner-heading {
  font-family: 'Volvo Novum Medium', Arial, sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 62px;
  text-align: center;
  margin-bottom: 1.25rem;
}

@media (max-width: 768px) {
  .banner-content {
    width: 80%;
    margin-top: 2.8rem;
  }
  .banner-heading {
    line-height: 32px;
  }

  .banner-date {
    font-size: 16px;
  }
}
