.date-picker-container {
  position: relative;
  width: 100%;
  max-width: 300px;
}

.date-input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.date-label {
  display: block;
  margin-bottom: 1px;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-primary);
  font-family:
    Volvo Novum Light,
    Arial,
    sans-serif;
}

.date-input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.date-input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

@media (max-width: 480px) {
  .date-picker-container {
    max-width: 100%;
  }
}
