.shareContainer {
  color: #4a4c4e;
  background-color: #fafafa;
}
.shareIcon {
  width: 60%;
  margin: 0 auto;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 35px;
  color: #707070;
  cursor: pointer;
}
.shareIcon a {
  text-decoration: none;
  color: #707070;
}
.shareIcon a:hover {
  color: #707070;
}
.shareIcon h4 {
  font-size: 14px;
  margin-top: 10px;
  font-family: 'Volvo Novum Light';
  font-weight: 300;
}
.shareContainer .form-control {
  border-radius: 3px;
  border: 0;
  font-family: 'Volvo Novum Medium';
  font-weight: 400;
  overflow: hidden;
}

.shareContainer .share-btn {
  background-color: #fff !important ;
  color: #1c6bba;
  font-family: 'Volvo Novum Medium';
  font-weight: 400;
  border: 0;
}

.shareContainer .share-textarea {
  display: block;
  width: 60%;
  margin: 0 auto;
  padding-top: 20px;
}
.shareContainer .share-textarea .btn:focus {
  background-color: #fff;
  outline: 0;
  border: 0;
}

.shareContainer .share-textarea .form-control:focus {
  box-shadow: none;
}
