.video-card {
  width: 290px;
  height: auto;
  display: flex;
  flex-direction: column;
}

.video-thumbnail {
  width: 100%;
  height: 163px;
  background-color: black;
  overflow: hidden;
  display: inline-block;
  position: relative;
}

.video-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0;
  transition: 0.5s all ease-in-out;
}

.live-thumbnail img {
  opacity: 0.7;
}

.live-indicator {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: var(--color-red-medium);
  color: var(--color-white);
  font-size: 10px;
  text-transform: uppercase;
  font-family: 'Volvo Novum Medium';
  padding: 0px 4px;
  line-height: 17px;
}

.video-thumbnail:hover img {
  transform: scale(1.1);
}

.video-card:hover .video-title,
.video-card:hover .channel-name,
.video-card:hover .broadcast-date {
  color: #1c6bba;
}

.video-title {
  font-size: 15px;
  font-family: 'Volvo Novum Medium';
  color: var(--text-primary);
  line-height: 19px;
  text-align: left;
  margin-top: 6px;
  transition: color 0.3s ease-in-out;
}

.channel-name,
.broadcast-date {
  font-size: 12px;
  font-family: 'Volvo Novum Light';
  color: var(--text-secondary);
  line-height: 14px;
  text-align: left;
  transition: color 0.3s ease-in-out;
}

.video-details a {
  text-decoration: none;
}
.video-details a:hover .video-title,
.video-details a:hover .channel-name,
.video-details a:hover .broadcast-date {
  color: #1c6bba;
}

@media (max-width: 820px) {
  .video-card {
    flex-direction: row;
    width: 100%;
    height: auto;
  }

  .video-thumbnail {
    /* width: auto; */
    height: auto;
    max-height: 63px;
    max-width: 100px;
    object-fit: cover;
    margin-right: 12px;
    flex-shrink: 0;
  }

  .video-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }

  .video-title {
    margin-top: -2px;
  }
}
