:root {
  --color-blue-darkest: #202a44;
  --color-blue-darker: #1b365d;
  --color-blue-light: #7089ac;
  --color-blue-lightest: #ced9e5;

  --color-grey-darkest: #53565a;
  --color-grey-darker: #888b8d;
  --color-grey-light: #a7a8a9;
  --color-grey-lighter: #c8c9c7;
  --color-grey-lightest: #fafafa;

  --color-black: #000000;
  --color-off-black: #141414;
  --color-white: #ffffff;

  --color-orange-dark: #a85f02;
  --color-orange-medium: #bf834b;
  --color-orange-light: #d9b48b;
  --color-orange-lightest: #f0dfc6;

  --color-red-dark: #622128;
  --color-red-medium: #9e2a2b;
  --color-red-light: #dda69d;
  --color-red-lightest: #f0ddd7;

  --color-green-dark: #22372b;
  --color-green-medium: #476d3b;
  --color-green-light: #a3b2a4;
  --color-green-lightest: #e0e7d9;

  --color-header-icon-dark: #2a2929;
  --color-header-icon-light: #23272f0d;

  --color-footer-bg-dark: #333333;
  --color-skelaton-bg-light: #e0e0e0;
}
