.dropdown-container {
  margin-bottom: 16px;
  font-family:
    Volvo Novum Light,
    Arial,
    sans-serif;
}

.dropdown-label {
  display: block;
  margin-bottom: 1px;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-primary);
}

.dropdown-label-required {
  color: var(--color-red-medium);
  margin-left: 4px;
}

.dropdown-select-wrapper {
  position: relative;
}

.dropdown-select {
  display: block;
  width: 100%;
  padding: 10px 12px;
  padding-right: 30px;
  font-size: 14px;
  line-height: 1.5;
  color: var(--text-primary);
  background-color: var(--background-primary);
  border: 1px solid var(--btn-color);
  border-radius: 3px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  text-align: left;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.dropdown-select:focus {
  outline: none;
}

.dropdown-select option {
  text-align: left;
}

.dropdown-select:disabled {
  background-color: #f3f4f6;
  cursor: not-allowed;
  opacity: 0.7;
}

.dropdown-select-with-icon {
  padding-left: 36px;
}

.dropdown-icon-wrapper {
  position: absolute;
  left: 12px;
  top: 48%;
  transform: translateY(-50%);
  color: var(--text-secondary);
  z-index: 1;
}

.dropdown-chevron-wrapper {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-secondary);
  pointer-events: none;
}
