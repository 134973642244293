.vl-description p {
  margin-top: 15px;
  margin-bottom: 15px;
  font-family:
    Volvo Novum Light,
    Arial,
    sans-serif;
  color: var(--text-secondary);
}

.vl-description p strong {
  font-family:
    Volvo Novum Medium,
    Arial,
    sans-serif;
}

.vl-description p a:hover {
  color: #1c6bba;
}
