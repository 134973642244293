.header {
  width: 100%;
  background-color: var(--background-secondary);
  position: relative;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 20px;
  position: relative;
}

.logo img {
  width: 104px;
  height: 8px;
}

.headerOptions {
  display: flex;
  gap: 12px;
  align-items: center;
}
