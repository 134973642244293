.navTabContainer {
  width: 100%;
  margin: 0 auto;
  background-color: var(--background-secondary);
  border-radius: 0;
}

.navTab {
  font-family: 'Volvo Novum Light';
  background-color: var(--background-secondary);
  font-size: 14px;
  color: var(--text-secondary);
  border-radius: 0;
  padding: 12px 24px;
  cursor: pointer;
}

.navTabButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: var(--background-secondary);

  padding-top: 7px;
}

.navTabs {
  display: flex;
}

.activeTab {
  background-color: var(--background-secondary);
}

.adminContent {
  width: 100% !important;
  margin: 0 auto;
  padding-bottom: 10px;
  background-color: var(--background-secondary);
}

.innerAdminContent {
  width: 80%;
  margin: 0 auto;
}

.navTabContent {
  height: auto;
  max-width: 80%;
  margin: 0 auto;
  background-color: var(--background-secondary);
  padding: 20px;
}

.navTabColor {
  color: #707070;
  font-weight: 300;
}

.navTab:hover .navTabIcon,
.navTab:hover {
  color: #3d5881;
}

.navTabFocus {
  font-family: 'Volvo Novum Medium';

  color: #3d5881;
  font-weight: 500;
}

.navTabIcon {
  margin: 5px;
  font-size: 20px;
}

.navTabDots {
  padding: 10px 20px !important;
  position: relative;
  display: none;
}

.navTabPopupContainer {
  position: absolute;
  min-width: 200px;
  right: 0;
  background-color: var(--background-secondary);
}

.navTabPopup {
  position: absolute;
  right: 0;
  top: 0;
}

.navTabPopup .navTab {
  display: flex;
}

.askQuestionTabPopup,
.shareTabPopup,
.dateTabPopup,
.chapterTabPopup {
  display: none;
}

@media only screen and (max-width: 992px) {
  .navTabContainer {
    width: 100%;
  }

  .navTabButtons {
    padding-top: 5px;
  }

  .navTabContent,
  .innerAdminContent {
    width: 100%;
    padding: 20px;
  }

  .navTab {
    font-size: 12px;
  }

  .navTabIcon {
    font-size: 12px;
  }
}

@media only screen and (max-width: 720px) {
  .navTabContent {
    max-width: 100%;
  }

  .navTabDots {
    display: block;
  }
}

@media only screen and (max-width: 520px) {
  .navTab {
    padding: 12px 12px;
  }
}

.vl-interaction-embed {
  grid-column: 1 / 11;
  display: grid;
}

.vl-slido-iframe {
  height: 100%;
  width: 100%;
  min-height: 700px;
}
