.paginationContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
}

.paginationButton {
  margin-left: 10px;
  padding: 0;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  border: 1.5px solid var(--border-color);
  color: var(--text-primary);
}

.paginationButton:focus {
  outline: none;
}

.paginationButton:disabled {
  color: var(--text-secondary);
  pointer-events: none;
  background-color: transparent;
  opacity: 0.5;
}
