.footer {
  height: 168px;
  background-color: var(--footer-bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 0 20px;
}

.footer > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.footer > ul > li {
  margin: 5px 10px;
}

.footer > ul > li > a {
  font-size: 14px;
  font-family:
    Volvo Novum Light,
    Arial,
    sans-serif;
  color: var(--text-secondary);
  text-decoration: none;
  padding: 8px 12px;
  transition: color 0.3s ease-in-out;
}

.footer > ul > li > a:hover {
  color: #007bff;
}

.footerCopyrightText {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-secondary);
  margin-top: 10px;
  font-family:
    Volvo Novum Light,
    Arial,
    sans-serif;
}
