.sideHeaderContainer {
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
  height: 64px;
}

.sideHeaderContainer img {
  margin-left: 10px;
  width: 86px;
  height: 36px;
}

.sideNavbarMenu {
  background-color: #fff;
  padding: 20px 0px;
  border-bottom: 1px solid #ebebeb;
}

.navbarMenuTab {
  background-color: #fff;
  padding: 12px 12px 12px 24px;
  font-size: 20px;
  font-weight: 500;
  width: 96%;
  cursor: pointer;
}

.navbarMenuTab:hover {
  background-color: #fafafa;
}

.sideNavbarContainer .sideNavbarCloseBtn {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 1px;
}

.sideNavbarCloseBtn:hover {
  background-color: #ebebeb;
}

.sideNavbarContainer .sideNavbarLeftBtn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.sideNavbarLeftBtn:hover {
  background-color: #ebebeb;
}

.sideNavbarMaxWidth {
  width: 400px;
  transition: 10s;
}

.sideNavbarMinWidth {
  width: 0;
}

.sideNavbarSocialIcons {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 22px;
}

.sideNavbarSocialIcons > svg {
  margin-right: 20px;
  cursor: pointer;
}

.sideNavbarrightIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
}

.sideNavbarContainer .menuBtn {
  color: var(--text-primary);
  font-family:
    Volvo Novum Medium,
    Arial,
    sans-serif;
  font-weight: 500;
  font-size: 14px;
  width: 38px;
  padding: 10px 0;
  margin-right: 8px;
  border: 0;
  border-radius: 0;
}

.sideNavbarContainer .menuBtn:hover {
  border-bottom: 1px solid #141414;
  outline: 0;
}
