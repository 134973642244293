.cta-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
}

.cta-text {
  flex: 1;
  font-size: 12px;
  font-family: 'Volvo Novum Light';
  padding-right: 20px;
}

.cta-text p {
  font-size: 16px;
  padding-right: 20px;
  color: black;
  margin-bottom: 5px;
}

.cta-button {
  padding: 12px 24px;
  border: 1px solid #3d5881 !important;
  color: #3d5881;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  background-color: transparent;
}

.cta-button:hover {
  background-color: #3d5881;
  color: white;
}

.horizontal-line {
  width: 100%;
  height: 2px;
  background-color: #ebebeb;
  margin-top: 30px;
  margin-bottom: 30px;
}
