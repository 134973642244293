.toggle-theme {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
}

.toggle-theme:hover {
  opacity: 0.8;
}

.theme-icon {
  font-size: 1.25rem;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.toggle-theme:hover .theme-icon {
  background-color: var(--header-icon);
}

.icon {
  color: var(--text-primary);
}
