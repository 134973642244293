@font-face {
  font-family: 'Volvo Novum Bold';
  src:
    url('./fonts/volvo-novum/Volvo Novum-Bold.woff') format('woff2'),
    url('./fonts/volvo-novum/Volvo Novum-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Volvo Novum Light';
  src:
    url('./fonts/volvo-novum/Volvo Novum-Light.woff2') format('woff2'),
    url('./fonts/volvo-novum/Volvo Novum-Light.woff') format('woff');
}

@font-face {
  font-family: 'Volvo Novum Regular';
  src:
    url('./fonts/volvo-novum/Volvo Novum-Regular.woff2') format('woff2'),
    url('./fonts/volvo-novum/Volvo Novum-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Volvo Novum Medium';
  src:
    url('./fonts/volvo-novum/Volvo Novum-Medium.woff2') format('woff2'),
    url('./fonts/volvo-novum/Volvo Novum-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Volvo Novum Broad Pro';
  src: url('./fonts/volvo-novum/VolvoBroadWeb.woff') format('woff');
}
.primaryBtn {
  border-radius: 3px;
  background: var(--background-primary);
  border: 1px solid var(--btn-color);
  color: var(--btn-color);
  font-family:
    Volvo Novum Medium,
    Arial,
    sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.35px;
  text-transform: uppercase;
  min-width: 100px;
  outline: none;
  min-height: 48px;
}
/* .primaryBtn:hover {
  border: 1px solid #fff;
  background-color: #2a609d !important;
  color: #fff;
} */
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.secondaryBtn {
  font-family: Volvo Novum Medium;
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid #fff;
  background-color: #2a609d;
  padding: 13px 30px !important;
}
.secondaryBtn:hover {
  background-color: #2a609d;
  outline: none;
  border: 1px solid #fff;
}

body {
  background-color: var(--background-primary);
  color: var(--text-primary);
}
