.filter-panel-container {
  height: 70vh;
  width: 100%;
  max-width: 48rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
}

.filter-panel-header {
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
}

.filter-panel-header h1 {
  font-family:
    Volvo Novum Medium,
    Arial,
    sans-serif;
  font-size: 18px;
  color: var(--color-off-black);
}

.close-button {
  display: inline-flex;
  height: 1.75rem;
  width: 1.75rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 9999px;
  background-color: var(--color-grey-lightest);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.filter-panel-content {
  display: flex;
  gap: 1rem;
  flex: 1;
  overflow: hidden;
}

.filter-category-container {
  flex: 1;
  min-width: 150px;
  height: 100%;
  margin-top: 0.25rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border: none;
  color: var(--color-off-black);
}

.filter-category-item {
  position: relative;
  width: 100%;
  border: none;
}

.active-indicator {
  background-color: var(--color-blue-light);
  border-radius: 0 0.5rem 0.5rem 0;
  height: 100%;
  width: 6px;
  position: absolute;
  left: 0;
}

.filter-category-item button {
  padding: 1rem 1rem;
  text-align: left;
  font-weight: 600;
  color: #374151;
  width: 100%;
}

.filter-divider {
  width: 1px;
  background-color: #e5e7eb;
}

.filter-data-container {
  flex: 2;
  margin-top: 0.75rem;
  padding-right: 1rem;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 1.5rem;
  color: var(--color-off-black);
}

.hidden {
  display: none;
}

.filter-panel-footer {
  margin-top: auto;
  position: relative;
  padding: 10px;
}

.footer-shadow {
  position: absolute;
  inset: 0;
  top: 0;
  height: 1rem;
  background-color: white;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1);
}

.footer-content {
  position: relative;
  background-color: white;
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem;
  gap: 1rem;
}

.clear-filters-button {
  color: var(--btn-color);
  font-family:
    Volvo Novum Medium,
    Arial,
    sans-serif;
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
}
