.adminmenuContainer {
  color: var(--text-secondary);
  background-color: var(--background-secondary);
}
.adminmenu-item {
  font-family: 'Volvo Novum Medium';
  border-bottom: 1px solid #e1dfdd;
  font-weight: 400;
  padding: 24px 0;
}
.adminmenu-item > a {
  color: var(--text-secondary);
  text-decoration: none;
}

.adminmenu-item > a:hover {
  color: #1c6bba;
}
.adminmenu-lastitem {
  font-family: 'Volvo Novum Medium';
  font-weight: 400;
  padding: 24px 0;
}
.adminmenu-lastitem > a {
  color: var(--text-secondary);
  text-decoration: none;
  padding-bottom: 10px;
}
.adminmenu-lastitem > a:hover {
  color: #1c6bba;
}
.adminmenuContainer .form-control {
  border-radius: 3px;
  border: 0;
  font-family: 'Volvo Novum Medium';
  font-weight: 400;
  overflow: hidden;
}

.adminmenuContainer .adminmenu-btn {
  background-color: #fff !important;
  color: #1c6bba;
  font-family: 'Volvo Novum Medium';
  font-weight: 400;
  border: 0;
}
.adminmenuContainer .adminmenu-textarea .btn:focus {
  background-color: #fff;
  outline: 0;
  border: 0;
}

.adminmenuContainer .adminmenu-textarea .form-control:focus {
  box-shadow: none;
}
