.video-card .skeleton {
  width: 290px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.skeleton-box {
  background-color: var(--skelaton-bg);
  animation: pulse 1.5s infinite ease-in-out;
}

.video-thumbnail.skeleton-box {
  width: 100%;
  height: 163px;
}

.video-title.skeleton-box {
  width: 80%;
  height: 16px;
}

.video-details {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.channel-name.skeleton-box {
  width: 50%;
  height: 12px;
}

.broadcast-date.skeleton-box {
  width: 40%;
  height: 12px;
}

@keyframes pulse {
  0% {
    background-color: var(--skelaton-bg);
  }
  50% {
    background-color: var(--skelaton-bg);
  }
  100% {
    background-color: var(--skelaton-bg);
  }
}

@media (max-width: 768px) {
  .video-card {
    flex-direction: row;
    width: 100%;
    height: auto;
  }

  .video-thumbnail {
    width: auto;
    height: auto;
    max-height: 63px;
    max-width: 100px;
    object-fit: cover;
    margin-right: 20px;
  }

  .video-details {
    width: 100%;
  }
}
