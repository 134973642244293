.hero-container {
  width: 100%;
  height: 444px;
  overflow: hidden;
  position: relative;
}
.hero-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.hero-content {
  z-index: 2;
  position: relative;
  margin-top: 5rem;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  color: var(--color-white);
  text-align: center;
}

.hero-content .preheader {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  font-family: 'Volvo Novum Light';
  color: var(--color-white);
}

.hero-content .channel-name {
  font-size: 48px;
  font-family: Volvo Novum Medium;
  font-style: normal;
  font-weight: 500;
  line-height: 62px;
  letter-spacing: 0;
  text-align: center;
  padding: 20px 0;
  color: var(--color-white);
}

.hero-content .description {
  font-size: 18px;
  font-weight: 400;
  font-family: 'Volvo Novum Light';
  color: var(--color-white);
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 767px) {
  .hero-content .channel-name {
    font-size: 32px;
    font-family: Volvo Novum Medium;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0;
    text-align: center;
    padding: 20px 0;
  }
  .hero-content {
    width: 85%;
  }
  .hero-container {
    height: 462px;
  }
  .hero-content .description {
    -webkit-line-clamp: 8;
    font-size: 16px;
  }
}

/* .hero-text {
  text-align: center;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.hero-text > h1 {
  font-family: Volvo Novum Medium;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 62px;
  letter-spacing: 0;
  text-align: center;
}
.heroDescription {
  padding: 30px 0;
  width: 50%;
  margin: 0 auto;
  color: #707070;
  font-family: 'Volvo Novum Light';
}
@media only screen and (max-width: 992px) {
  .hero-text > h1 {
    font-size: 32px;
    line-height: 32px;
  }
  .hero-container > img {
    height: 400px;
  }
  .heroDescription {
    padding: 20px;
    width: 100%;
  }
} */
