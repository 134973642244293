.carousalVideoCard {
  display: flex;
  gap: 20px;
}

@media only screen and (max-width: 992px) {
  .carousalVideoCard {
    flex-direction: column;
  }
}

.carousalMainHeading {
  margin-top: 20px;
  margin-bottom: 24px;
  text-align: center;
  font-family: Volvo Novum Medium;
  font-size: 24px;
}

.skelatonPagination {
  height: 50px;
}

@media only screen and (max-width: 992px) {
  .carousalMainHeading {
    text-align: left;
    font-size: 20px;
  }
}

@media only screen and (max-width: 400px) {
  .skelatonPagination {
    height: 0px;
  }
}
