.countdown-wrapper {
  font-family: Volvo Novum Medium;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0px;
}

.time-section {
  padding: 0px 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #254864 !important;
}

.time {
  margin: 0;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.time-text {
  font-size: 8px;
  font-weight: 300;
}

.circularProgressBar {
  width: 80px;
  height: 80px;
  transition: 'strock-dashoffset 2s ease 0s';
  margin-left: 20px;
}

.countdown-text h5 {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center !important;
}
