.signinContainer {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 100px;
}
.signIn-h1 {
  font-family: 'Volvo Novum Medium';
  font-size: 50px;
  font-weight: 500;
  line-height: 62px;
  letter-spacing: 0;
  text-align: center;
  margin-top: 10px;
}
.loginHeading {
  margin-bottom: 50px;
}
.signinContainer .validateMessage {
  display: block;
  font-family: 'Volvo Novum Light';
  font-weight: 300;
  font-size: 12px;
  color: #fff;
}
.login-h1 {
  font-size: 56px;
  font-weight: 500;
  line-height: 62px;
  letter-spacing: 0;
  text-align: center;
}
.signinContainer .pinCodeBtn {
  margin-top: 10px;
  padding: 13px 30px;
}
.loginText {
  font-family: 'Volvo Novum Medium';
  font-size: 20px;
  font-weight: 500;
}
.triangle {
  color: #fff;
}
.formContainer {
  position: relative;
  background-color: #3d5881;
  padding: 40px 90px 20px 90px;
  font-family: Volvo Novum light;
  font-size: 10px;
}

.signinContainer .btn-primary {
  margin-top: 30px;
  font-family: 'Volvo Novum Medium';
  margin: 30px 0 0;
  padding: 13px 30px;
  border-radius: 3px;
  color: #ffffff;
  border-color: #ffffff;
}

.signinContainer .form-control {
  width: 100%;
  max-width: 400px;
  min-width: 0px;
  margin: 0 auto;
  padding: 10px;
  display: block;
  box-sizing: border-box;
  outline: none;
  resize: none;
}
.signinTitleContainer {
  position: relative;
  text-align: center;
}
.pinDateNtime {
  font-family: 'Volvo Novum Medium';
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  margin-bottom: 20px;
  margin-top: 20px;
}
.pinTitle {
  font-size: 32px;
  font-family: 'Volvo Novum Medium';
  margin-bottom: 50px;
}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #2a609d;
  position: absolute;
  top: -8px;
  left: 48%;
}
.signinContainer .formContainer .form-control {
  font-family: 'Volvo Novum Light';
  font-weight: 300;
  border-radius: 0;
  padding: 13px;
  box-sizing: border-box;
}
.signinContainer .signinTopBtn {
  width: 250px;
  padding: 13px 30px;
  margin-bottom: 30px;
}
.signinContainer .emailFormBtn,
.signinContainer button {
  color: #ffffff;
  border-color: #ffffff;
  background-color: transparent;
}

.signinContainer .emailFormBtn:focus,
.signinContainer .emailFormBtn:active,
.signinContainer button:focus,
.signinContainer button:active {
  color: #ffffff;
  border-color: #ffffff;
  background-color: transparent;
  outline: none;
}

.signinContainer button.btn-primary,
.signinContainer button.btn-primary:focus,
.signinContainer button.btn-primary:active {
  color: #ffffff;
  border-color: #ffffff;
  background-color: transparent;
  outline: none;
}

.button-container .send button,
.button-container .send button.btn-primary,
.button-container .send button:focus,
.button-container .send button:active {
  color: #ffffff;
  border-color: #ffffff;
  background-color: transparent;
  outline: none;
}

.button-container .disabled,
.button-container .disabled:focus,
.button-container .disabled:active {
  color: #707070;
  border-color: #707070;
  background-color: transparent;
  outline: none;
}

button:focus-visible,
.signinContainer .emailFormBtn:focus-visible,
.signinContainer button:focus-visible {
  outline: none;
  box-shadow: none;
}
.signInParagraph {
  margin-top: 20px;
}
small {
  font-family: 'Volvo Novum Light';
  font-size: 12.8px;
}

.signinContainer .button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.signinContainer .button-container .send {
  margin-top: 20px;
}

.signinContainer .button-container .disabled {
  color: #707070;
  border-color: #707070;
}
.signinContainer .errorText,
.signinContainer .successText {
  color: #707070;
  font-family: 'Volvo Novum Light';
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
  width: 100%;
}

.validateMessage {
  text-align: center;
  color: #fff;
  font-size: 0.9rem;
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 991px) {
  .signinContainer {
    width: 70%;
  }
  .formContainer {
    padding: 20px 140px;
  }
  .signinContainer > h1 {
    font-size: 32px;
    line-height: 32px;
  }
  .pinlogin-h1 {
    font-size: 32px;
  }
}
@media screen and (max-width: 786px) {
  .signinContainer {
    width: 80%;
  }
  .formContainer {
    padding: 20px 100px;
  }
  .signinContainer > h1 {
    font-size: 32px;
    line-height: 32px;
  }
  .pinlogin-h1 {
    font-size: 32px;
  }
}
@media screen and (max-width: 576px) {
  .signinContainer {
    width: 90%;
  }
  .formContainer {
    padding: 20px;
  }
  .signinContainer > h1 {
    font-size: 32px;
    line-height: 32px;
  }
  .pinlogin-h1 {
    font-size: 32px;
  }
}
