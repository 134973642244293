.video-tab-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
}

.top-row,
.bottom-row {
  display: flex;
  align-items: center;
  gap: 16px;
}

.filter-btn {
  border-radius: 3px;
  border: 1px solid var(--btn-color);
  background: var(--background-primary);
  color: var(--text-primary);
  font-family:
    Volvo Novum Light,
    Arial,
    sans-serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  margin-top: 8px;
  position: relative;
  white-space: nowrap;
  min-width: 90px;
  position: relative;
}

.filter-btn.active {
  background: var(--accent-secondary);
  color: var(--button-text);
  border-color: var(--accent-primary);
}

.search-container {
  margin-top: 8px;
  width: 100%;
}

.sort-container {
  margin-top: 8px;
  min-width: 142px;
}

.filter-mobile {
  display: none;
}

@media (min-width: 768px) {
  .video-tab-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }

  .top-row {
    display: flex;
    flex: 1;
    max-width: 85%;
    margin-top: 6px;
  }

  .bottom-row {
    display: flex;
    justify-content: flex-end;
    max-width: 30%;
  }

  .search-container {
    flex: 1;
  }

  .filter-mobile {
    display: none;
  }

  .filter-desktop {
    display: flex;
  }

  .sort-container {
    width: auto;
  }
}

@media (max-width: 767px) {
  .video-tab-container {
    flex-direction: column;
    gap: 8px;
  }

  .top-row,
  .bottom-row {
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .top-row {
    width: 100%;
  }

  .search-container {
    flex: 1;
    min-width: 100%;
  }

  .bottom-row {
    width: 100%;
  }

  .sort-container {
    flex: 1;
  }

  .filter-desktop {
    display: none;
  }

  .filter-mobile {
    display: flex;
    margin-top: 4%;
  }
}

.filter-count {
  background: var(--color-red-medium);
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -8px;
  right: -8px;
}
