/* ProfileCard.css */
.profile-container {
  display: flex;
  align-items: center;
  width: auto;
  background-color: transparent;
}

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px; /* Space between image and name */
}

.profile-name {
  margin-bottom: 0; /* Remove bottom margin from h5 */
}
