.video-flex-container {
  padding: 12px 72px;
}

.video-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
}

.video-flex > * {
  flex: 1 1 calc(25% - 24px);
  max-width: calc(25% - 24px);
  box-sizing: border-box;
}

.footer-primary-btn {
  border-radius: 3px;
  background: var(--background-primary);
  border: 1px solid var(--btn-color);
  color: var(--btn-color);
  font-family:
    Volvo Novum Medium,
    Arial,
    sans-serif;
  font-size: 14px;
  padding: 4px 8px;
}

.footer-secondary-btn {
  color: var(--btn-color);
  font-family:
    Volvo Novum Medium,
    Arial,
    sans-serif;
  font-size: 14px;
  border: none;
  background: none;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .video-flex > * {
    flex: 1 1 calc(33.33% - 24px);
    max-width: calc(33.33% - 24px);
  }
}

@media (max-width: 767px) {
  .video-flex > * {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .video-flex-container {
    padding: 16px;
  }
}
